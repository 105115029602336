import api from "@utils/api";

export const Logout = async () => {
    return window.axios.post('/logout')
}

export const LogoutByTokenId = async (token_id) => {
    return api.call('post', '/logout-by-token-id', { token_id })
}

export const LogoutAllSessions = async () => {
    return api.call('post', '/logout-all-sessions')
}

export const GetAuthTokens = async (queryParams) => {
    return api.call('get', `/get-auth-tokens${queryParams ?? ''}`)
}