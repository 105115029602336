import { createRouter, createWebHistory } from 'vue-router'
import authModuleRoutes from '@modules/auth/routes'
import dashboardRoutes from '@modules/dashboard/routes'
import documentRoutes from '@modules/document/routes'
import adminRoutes from '@modules/admin/routes'
import frameRoutes from '@modules/frame/routes'
import articleRoutes from '@modules/article/routes'
import changelogRoutes from '@modules/changelog/routes'
import supportRoutes from '@modules/support/routes'
import roleTypeRoutes from '@modules/role_type/routes'
import productRoutes from '../modules/product/routes/ProductRoutes'
import subscriptionRoutes from '@modules/subscription/routes'
import invoiceRoutes from '@modules/invoice/routes'
import beforeEach from './middleware/BeforeEach'
import afterEach from './middleware/AfterEach'

let routes = [
  ...authModuleRoutes,
  ...dashboardRoutes,
  ...documentRoutes,
  ...adminRoutes,
  ...frameRoutes,
  ...articleRoutes,
  ...changelogRoutes,
  ...supportRoutes,
  ...roleTypeRoutes,
  ...productRoutes,
  ...subscriptionRoutes,
  ...invoiceRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
