import Internal from '@layouts/Internal'

export default [
	{
		path: '/document',
		component: () => import('@layouts/components/EmptyComponent'),
		meta: {
			onMenu: true,
			onBreadcrumb: false,
			title: 'Document',
		},
		children: [
			{
				name: 'DocumentIndex',
				path: '/document',
				component: () => import('@modules/document/views/DocumentIndex'),
				meta: {
					middlewareAuth: true,
					onBreadcrumb: true,
					title: 'All Documents',
					onMenu: true,
					layout: Internal,
				},
			},
			{
				path: '',
				redirect: {
					name: 'DocumentIndex',
				},
			},
			{
				name: 'DocumentCreate',
				path: 'create',
				component: () => import('@modules/document/views/DocumentCreate'),
				meta: {
					middlewareAuth: true,
					onBreadcrumb: true,
					title: 'Document Create',
					onMenu: true,
					layout: Internal,
				},
			},
			{
				name: 'DocumentEdit',
				path: 'edit/:id',
				component: () => import('@modules/document/views/DocumentCreate'),
				meta: {
					middlewareAuth: true,
					onBreadcrumb: true,
					layout: Internal,
				},
			},
		],
	},
]
