import Internal from '@layouts/Internal'
import {defineAsyncComponent, defineComponent, h} from "vue";

export default [
  {
    path: '',
    component: () => import('@layouts/components/EmptyComponent'),
    meta: {
      onMenu: true,
      onBreadcrumb: true,
      title: 'Frames',
    },
    children: [
      {
        name: 'FrameList',
        path: 'frame/list',
        component: () => import('@modules/frame/views/FrameList'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'All frames',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'FrameCreate',
        path: 'frame/new',
        component: () => import('@modules/frame/views/FrameCreate'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Frame Create',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'FrameDetails',
        path: 'frame/:frame_id/details',
        props: true,
        component: () => import('@modules/frame/views/FrameDetails'),
        redirect: to => ({
          name: 'FrameDetailsInfo'
        }),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Frame Details',
          layout: Internal,
          alternativeMenu: 'FrameList',
        },
        children: [
          {
            name: 'FrameDetailsInfo',
            path: 'info',
            props: true,
            component: () => import('@modules/frame/views/FrameDetails'),
            meta: {
              title: 'Info',
              componentName: 'Info'
            }
          },
          {
            name: 'FrameDetailsRelationships',
            path: 'relationships',
            props: true,
            component: () => import('@modules/frame/views/FrameDetails'),
            meta: {
              title: 'Relationships',
              componentName: 'Relationships'
            }
          },
          {
            name: 'FrameDetailsLimits',
            path: 'limits',
            props: true,
            component: () => import('@modules/frame/views/FrameDetails'),
            meta: {
              title: 'Limits',
              componentName: 'Limits'
            }
          },
        ]
      },
      {
        path: '',
        redirect: {
          name: 'FrameList',
        },
      },
    ],
  },
]
