const context = require.context('.', false, /\.js$/)
let routes = []

context.keys().forEach((fileName) => {
	if (fileName === './index.js') return

	routes.push(...context(fileName).default)
})

export default routes
