<template>
  <div class="aside-user">
    <!--begin::User-->
    <div
      class="aside-user d-flex align-items-sm-center justify-content-center py-5"
    >
      <!--begin::User image-->
      <div class="me-5">
        <!--begin::Symbol-->
        <div
          class="symbol symbol-40px cursor-pointer"
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-overflow="true"
        >
          <img :src="defaultAvatar" alt="" />
        </div>
        <!--end::Symbol-->
        <!--begin::User account menu-->
        <!--<div
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        >
          <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
              
              <div class="symbol symbol-50px me-5">
                <img title="Avatar" :src="defaultAvatar" />
              </div>
              <div class="d-flex flex-column">
                <div class="fw-bolder d-flex align-items-center fs-5">
                  {{ userName }}
                  <span
                    class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
                    >Pro</span
                  >
                </div>
                <a
                  href="#"
                  class="fw-bold text-muted text-hover-primary fs-7"
                  >{{ userEmail }}</a
                >
              </div>
            </div>
          </div>
          <div class="separator my-2"></div>
          <div class="menu-item px-5">
            <a href="/good/account/overview.html" class="menu-link px-5"
              >My Profile</a
            >
          </div>
          <div class="menu-item px-5">
            <a href="/good/apps/projects/list.html" class="menu-link px-5">
              <span class="menu-text">My Projects</span>
              <span class="menu-badge">
                <span
                  class="badge badge-light-danger badge-circle fw-bolder fs-7"
                  >3</span
                >
              </span>
            </a>
          </div>
          <div
            class="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="right-end"
          >
            <a href="#" class="menu-link px-5">
              <span class="menu-title">My Subscription</span>
              <span class="menu-arrow"></span>
            </a>
            <div class="menu-sub menu-sub-dropdown w-175px py-4">
              
              <div class="menu-item px-3">
                <a href="/good/account/referrals.html" class="menu-link px-5"
                  >Referrals</a
                >
              </div>
              
              <div class="menu-item px-3">
                <a href="/good/account/billing.html" class="menu-link px-5"
                  >Billing</a
                >
              </div>
              <div class="menu-item px-3">
                <a href="/good/account/statements.html" class="menu-link px-5"
                  >Payments</a
                >
              </div>
              <div class="menu-item px-3">
                <a
                  href="/good/account/statements.html"
                  class="menu-link d-flex flex-stack px-5"
                  >Statements
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="View your statements"
                    aria-label="View your statements"
                  ></i
                ></a>
              </div>
              <div class="separator my-2"></div>
              <div class="menu-item px-3">
                <div class="menu-content px-3">
                  <label
                    class="form-check form-switch form-check-custom form-check-solid"
                  >
                    <input
                      class="form-check-input w-30px h-20px"
                      type="checkbox"
                      value="1"
                      checked="checked"
                      name="notifications"
                    />
                    <span class="form-check-label text-muted fs-7"
                      >Notifications</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-item px-5">
            <a href="/good/account/statements.html" class="menu-link px-5"
              >My Statements</a
            >
          </div>
          <div class="separator my-2"></div>
          <div
            class="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="right-end"
          >
            <a href="#" class="menu-link px-5">
              <span class="menu-title position-relative"
                >Language
                <span
                  class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
                  >English
                  <img
                    class="w-15px h-15px rounded-1 ms-2"
                    src="/good/assets/media/flags/united-states.svg"
                    alt="" /></span
              ></span>
            </a>
            <div class="menu-sub menu-sub-dropdown w-175px py-4">
             
              <div class="menu-item px-3">
                <a
                  href="/good/account/settings.html"
                  class="menu-link d-flex px-5 active"
                >
                  <span class="symbol symbol-20px me-4">
                    <img
                      class="rounded-1"
                      src="/good/assets/media/flags/united-states.svg"
                      alt=""
                    /> </span
                  >English</a
                >
              </div>
              <div class="menu-item px-3">
                <a
                  href="/good/account/settings.html"
                  class="menu-link d-flex px-5"
                >
                  <span class="symbol symbol-20px me-4">
                    <img
                      class="rounded-1"
                      src="/good/assets/media/flags/spain.svg"
                      alt=""
                    /> </span
                  >Spanish</a
                >
              </div>
              <div class="menu-item px-3">
                <a
                  href="/good/account/settings.html"
                  class="menu-link d-flex px-5"
                >
                  <span class="symbol symbol-20px me-4">
                    <img
                      class="rounded-1"
                      src="/good/assets/media/flags/germany.svg"
                      alt=""
                    /> </span
                  >German</a
                >
              </div>
              
              <div class="menu-item px-3">
                <a
                  href="/good/account/settings.html"
                  class="menu-link d-flex px-5"
                >
                  <span class="symbol symbol-20px me-4">
                    <img
                      class="rounded-1"
                      src="/good/assets/media/flags/japan.svg"
                      alt=""
                    /> </span
                  >Japanese</a
                >
              </div>
              
              <div class="menu-item px-3">
                <a
                  href="/good/account/settings.html"
                  class="menu-link d-flex px-5"
                >
                  <span class="symbol symbol-20px me-4">
                    <img
                      class="rounded-1"
                      src="/good/assets/media/flags/france.svg"
                      alt=""
                    /> </span
                  >French</a
                >
              </div>
            </div>
          </div>
          <div class="menu-item px-5 my-1">
            <a href="/good/account/settings.html" class="menu-link px-5"
              >Account Settings</a
            >
          </div>
          <div class="menu-item px-5">
            <a
              href="/good/authentication/flows/basic/sign-in.html"
              class="menu-link px-5"
              >Sign Out</a
            >
          </div>
          
          <div class="separator my-2"></div>
          <div class="menu-item px-5">
            <div class="menu-content px-5">
              <label
                class="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                for="kt_user_menu_dark_mode_toggle"
              >
                <input
                  class="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  name="mode"
                  id="kt_user_menu_dark_mode_toggle"
                  data-kt-url="/good/dark/index.html"
                />
                <span class="pulse-ring ms-n1"></span>
                <span class="form-check-label text-gray-600 fs-7"
                  >Dark Mode</span
                >
              </label>
            </div>
          </div>
        </div> -->
      </div>
      <div class="flex-row-fluid flex-wrap">
        <div class="d-flex align-items-center flex-stack">
          <div class="me-2">
            <a
              href="javascript:;"
              class="text-gray-800 text-hover-primary fs-6 fw-bold lh-0"
              >{{ userName }}</a
            >
            <span class="text-gray-400 fw-bold d-block fs-8">{{
              userEmail
            }}</span>
          </div>
          <a
            href="javascript:;"
            @click="Logout()"
            class="btn btn-icon btn-active-color-primary me-n4"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="End session and signout"
          >
            <span class="svg-icon svg-icon-2 svg-icon-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.3"
                  width="12"
                  height="2"
                  rx="1"
                  transform="matrix(-1 0 0 1 15.5 11)"
                  fill="black"
                ></rect>
                <path
                  d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                  fill="black"
                ></path>
                <path
                  d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                  fill="#C4C4C4"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_AVATAR } from '@utils/constants'
export default {
  name: 'UserModal',
  data() {
    return {
      defaultAvatar: DEFAULT_AVATAR,
    }
  },
  computed: {
    ...mapGetters('user', ['current_frame', 'current_user']),
    userAvatar() {
      return this.current_user && this.current_user.avatar_url
        ? this.current_user.avatar_url
        : DEFAULT_AVATAR
    },
    userName() {
      return this.current_user && this.current_user.name
        ? this.current_user.name
        : ''
    },
    userEmail() {
      return this.current_user && this.current_user.email
        ? this.current_user.email
        : ''
    },
  },
  methods: {
    ...mapActions('auth', ['Logout']),
  },
}
</script>

<style lang="scss" scoped>
.aside-user {
  .menu {
    &.show {
      position: absolute;
      bottom: 5.8rem;
      left: 2rem;
      z-index: 105;
    }
  }
}
</style>
