import Internal from '@layouts/Internal'

export default [
    {
        path: '/invoices',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Faturas',
        },
        children: [
            {
                name: 'InvoiceList',
                path: 'index',
                component: () => import('@modules/invoice/views/InvoiceList.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todas as faturas',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'InvoiceCreate',
                path: 'create',
                component: () => import('@modules/invoice/views/InvoiceCreateForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar fatura',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'InvoiceDetails',
                path: ':id/details',
                props: true,
                component: () => import('@modules/invoice/views/InvoiceForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar fatura',
                    layout: Internal,
                    alternativeMenu: 'InvoiceList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'InvoiceList',
                },
            },
        ],
    },
]