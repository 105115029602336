import Internal from '@layouts/Internal'

export default [
    {
        path: '/subscriptions',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Assinaturas',
        },
        children: [
            {
                name: 'SubscriptionList',
                path: 'index',
                component: () => import('@modules/subscription/views/SubscriptionList.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todas as assinaturas',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'SubscriptionCreate',
                path: 'create',
                component: () => import('@modules/subscription/views/SubscriptionForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar assinatura',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'SubscriptionDetails',
                path: ':id/details',
                props: true,
                component: () => import('@modules/subscription/views/SubscriptionForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar assinatura',
                    layout: Internal,
                    alternativeMenu: 'SubscriptionList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'SubscriptionList',
                },
            },
        ],
    },
]