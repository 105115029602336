//IMAGEM PADRÃO QUANDO USUÁRIO NÃO POSSUI IMAGEM
export const DEFAULT_AVATAR = require('@images/user.png')

export const LOGO_OLIE_ERP_LIGHT_SYMBOL = require('@images/logo-olie-erp-light-symbol.svg')
export const LOGO_OLIE_ERP_BLACK_SYMBOL = require('@images/logo-olie-erp-black-symbol.svg')

//STATUS ATIVIDADES
export const TASK_STATUS = [
  { name: 'Diferente de Concluida', value: 66 },
  { name: 'Não iniciada', value: 1 },
  { name: 'Em execução', value: 2 },
  { name: 'Pausada', value: 3 },
  { name: 'Pendente', value: 4 },
  { name: 'Concluida', value: 5 },
  { name: 'Aguard. revisão', value: 6 },
  { name: 'Revisada', value: 7 },
  { name: 'Cancelada', value: 8 },
]

//STATUS DE BUSCA GERAL
export const GENERAL_STATUS = [
  { value: 1, name: 'Hoje' },
  { value: 2, name: 'Amanhã' },
  { value: 5, name: 'Atrasadas' },
]

//DIAS DA SEMANA
export const WEEK_DAYS = [
  { name: 'Domingo', initials: 'Dom', value: 1 },
  { name: 'Segunda', initials: 'Seg', value: 2 },
  { name: 'Terça', initials: 'Ter', value: 3 },
  { name: 'Quarta', initials: 'Qua', value: 4 },
  { name: 'Quinta', initials: 'Qui', value: 5 },
  { name: 'Sexta', initials: 'Sex', value: 6 },
  { name: 'Sabado', initials: 'Sab', value: 7 },
]

//MESES DO ANO
export const YEAR = [
  { name: 'Janeiro', initials: 'Jan', value: 1 },
  { name: 'Fevereiro', initials: 'Fev', value: 2 },
  { name: 'Março', initials: 'Mar', value: 3 },
  { name: 'Abril', initials: 'Abr', value: 4 },
  { name: 'Maio', initials: 'Mai', value: 5 },
  { name: 'Junho', initials: 'Jun', value: 6 },
  { name: 'Julho', initials: 'Jul', value: 7 },
  { name: 'Agosto', initials: 'Ago', value: 8 },
  { name: 'Setembro', initials: 'Set', value: 9 },
  { name: 'Outubro', initials: 'Out', value: 10 },
  { name: 'Novembro', initials: 'Nov', value: 11 },
  { name: 'Dezembro', initials: 'Dez', value: 12 },
]

//DOMINIO PADRAO
export const DEFAULT_DOMAIN = process.env.VUE_APP_DEFAULT_DOMAIN

//SCHEMA EM UTILIZAÇÃO ( HTTP OU HTTPS)
export const DEFAULT_SCHEMA = process.env.VUE_APP_WEBSOCKET_APP_SCHEME
