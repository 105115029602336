import Internal from '@layouts/Internal'

export default [
    {
        path: '/products',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Produtos',
        },
        children: [
            {
                name: 'ProductList',
                path: 'index',
                component: () => import('@modules/product/views/ProductList.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todos os produtos',
                    onMenu: true,
                    layout: Internal,
                },
            }, 
            {
                name: 'ProductCreate',
                path: 'create',
                component: () => import('@modules/product/views/ProductForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar produto',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'ProductDetails',
                path: ':id/details',
                props: true,
                component: () => import('@modules/product/views/ProductForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar produto',
                    layout: Internal,
                    alternativeMenu: 'ProductList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'ProductList',
                },
            },
        ],
    },
] 